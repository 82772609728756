/** @format */

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: Quicksand !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* p {
  margin: 0;
}

code {
  font-family: Quicksand !important;
}

html {
  font-family: Quicksand !important;
}

::-webkit-scrollbar {
  width: 10px;
}

.fa-trash {
  color: #a91d3a;
  cursor: pointer;
  font-size: 20px;
}

.apexcharts-text tspan,
apexcharts-legend-text,
apexcharts-legend-series span {
  font-family: Quicksand !important;
  font-weight: 600;
}

::-webkit-scrollbar {
  width: 4px;
  background-color: #f1ece5;
  cursor: pointer;
}
::-webkit-scrollbar-thumb {
  background-color: #c3c1bc;
  cursor: pointer;
}
ul,
ol {
  list-style: disc;
}

.main_hoc {
  background-color: rgb(242, 243, 248);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: all;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  width: 100%;
  height: 100vh;
  z-index: 50;
}

@media (max-width: 768px) {
  .main_hoc {
    padding: 0 !important;
  }
}

.Login_section {
  min-height: 100vh;
  background-image: url("./assests/Images/background.png");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login_page {
  width: 450px;
  background-color: #fff;
  padding-top: 20px;
}

.login_page .logoImg {
  width: 150px;
  margin: auto;
}

.Login_section .login_page .content {
  border-top: 1px solid #bfbfbf;
  padding: 20px;
  border-bottom: 1px solid #bfbfbf;
  margin-top: 20px;
}

.Login_section .login_page .content h4 {
  font-size: 25px;
  font-weight: 600;
  margin: 0;
}
.Login_section .login_page .content p {
  font-size: 16px;
  color: #77878f;
  margin: 0;
}

.login_page form {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.login_page form .input-group {
  border: 1px solid #bfbfbf;
  padding: 10px;
  margin-top: 8px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.login_page form .Main_div {
  margin-top: 10px;
}
.login_page form .Main_div p {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}
.login_page form .input-group img {
  width: 15px;
  height: 15px;
}
.login_page form .input-group input {
  flex: 1;
  outline: none;
  border: none;
}

.login_page form button {
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  padding: 10px;
  border-radius: 0;
  background-color: #a50e0e;
  color: #fff;
  border: 1px solid #a50e0e;
}

.login_page form .remember_me {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.login_page form .remember_me .check {
  display: flex;
  gap: 5px;
  align-items: center;
}

.login_page form .remember_me .check input {
  width: 20px;
  height: 20px;
}

.login_page form .remember_me .check p {
  margin: 0;
  font-size: 16px;
  column-rule: #475156;
}

.login_page form .remember_me a {
  color: #252a34;
  font-size: 16px;
  margin: 0;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
}

.or-div {
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
}
.or-div div {
  height: 2px;
  background-color: #e4e7e9;
  width: 50%;
  margin: auto;
  height: 1px;
}
.or-div p {
  margin: 0 10px;
}

.sidebar {
  padding: 1.5rem;
  height: auto;
  min-height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  border-right: 1px solid #e8e8e8;
}
.sidebar .logoImg {
  width: 140px;
  display: block;
  margin: auto;
}
.sidebar nav a {
  color: #000;
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  cursor: pointer;
  letter-spacing: 0.05em;
  font-size: 14px;
  border-radius: 5px;
  text-decoration: none;
  padding: 10px;
  font-weight: 600;
}
.sidebar nav a:hover {
  background-color: #a50e0e;
  color: #fff;
}
.sidebar nav a.active {
  background-color: #a50e0e;
  color: #fff;
}
.sidebar .nav-link {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  height: 100%;
  flex: 1;
  max-height: 80vh;
  overflow-y: auto;
}

.Navbar {
  padding-top: 10px;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  display: flex;
  justify-content: space-between;
}
.Navbar .left-div {
  max-width: 600px;
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
}

.Navbar .search-bar {
  max-width: 388px;
  border: 1px solid #f5f6fa;
  border-radius: 50px;
  background-color: #d5d5d5;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  gap: 10px;
  width: 100%;
}
.Navbar .search-bar i {
  width: 15px;
}
.Navbar .search-bar input {
  background-color: transparent;
  flex: 1;
  outline: none;
  padding: 3px;
}
.Navbar .content {
  display: flex;
  gap: 20px;
  align-items: center;
}
.Navbar .content .userimg {
  width: 44px;
  height: 44px;
  border-radius: 100%;
  object-fit: cover;
}
.Navbar .content div {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.Navbar .content .fa-bell {
  font-size: 25px;
}
.Navbar .content div p {
  margin: 0;
  font-size: 14px;
  font-weight: 700;
}
.Navbar .content div span {
  font-size: 12px;
  font-weight: 600;
}
.Navbar .content .fa-caret-down {
  width: 30px;
  height: 30px;
  border: 1px solid #ddd;
  line-height: 30px;
  text-align: center;
  border-radius: 100%;
  font-size: 20px;
  cursor: pointer;
}

/* golu css start */
.section {
  padding-top: 20px;
  padding-bottom: 20px;
}
.section-title {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
  padding: 10px;
  padding-bottom: 0;
}

.dashboard-cards {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 1.5rem;
  padding: 20px;
}

.dashboard-cards .card {
  background-color: #fff;
  border: 1px solid #fff;
  width: 350px;
  box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.08);
  padding: 2rem;
  border-radius: 10px;
}
.dashboard-cards .card .above {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}
.dashboard-cards .card .above .left {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.dashboard-cards .card .above p {
  margin: 0;
}
.dashboard-cards .card .above p.heading {
  font-size: 16px;
}
.dashboard-cards .card .above p.count {
  font-size: 28px;
  font-weight: 600;
}
.dashboard-cards .card .growth {
  font-size: 16px;
  margin-top: 20px;
  font-weight: 600;
}
.dashboard-cards .card .growth.green i,
.dashboard-cards .card .growth.green span {
  color: #00b69b;
}
.dashboard-cards .card .growth.red i,
.dashboard-cards .card .growth.red span {
  color: #f93c65;
}

.dashboard-cards .card .above img {
  width: 40px;
  height: auto;
}

.user-stats {
  padding: 20px;
}
.user-stats .Main {
  box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.08);
  padding: 20px;
  background-color: #fff;
  border: none;
}
.user-stats .filteration {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}

.user-stats .filteration .heading {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}
.user-stats .filteration select {
  padding: 10px;
  border: 1px solid #d5d5d5;
  color: #2b3034;
  border-radius: 5px;
}

.filteration-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 20px;
}

.filteration-div p {
  margin: 0;
  font-size: 22px;
  font-weight: 600;
}

.filteration-div .Main {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
}
.filteration-div .Main button.create-new {
  background-color: #a50e0e;
  color: #fff;
  border: 1px solid #a50e0e;
  border-radius: 5px;
  padding: 10px;
  min-width: 160px;
  width: 100%;
  font-size: 14px;
}
.filteration-div .Main select {
  padding: 10px;
  border: 1px solid #d5d5d5;
  color: #2b3034;
  border-radius: 5px;
}
.filteration-div .Main .search-div {
  border: 1px solid #d5d5d5;
  color: #2b3034;
  display: flex;
  width: 215px;
  padding: 10px;
  align-items: center;
}
.filteration-div .Main .search-div input {
  outline: none;
  font-size: 16px;
}

.overflow-X {
  padding: 20px;
  overflow-x: auto;
}
.overflow-X .colored_table {
  width: 100%;
}
.overflow-X .colored_table thead tr {
  background-color: #f1f4f9;
  border: 1px solid #f1f4f9;
  border-radius: 10px;
}

.overflow-X .colored_table thead tr th,
.overflow-X .colored_table tbody tr td {
  padding: 10px;
  font-size: 14px;
}
.overflow-X .colored_table tbody tr td {
  font-weight: 600;
}
.overflow-X .colored_table tbody tr td .merged-div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.overflow-X .colored_table tbody tr td .merged-div img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
}

.boxShad {
  padding: 20px;
}
.boxShad .apply {
  box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.08);
}

.overflow-X .colored_table .dropdown-toggle::after {
  display: none;
}
.icon-merged {
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 600;
  width: 100%;
  padding: 5px;
  transition: 0.3s;
  font-family: Quicksand !important;
}

.icon-merged.delete {
  color: #ed1b24;
}
.icon-merged:focus,
.icon-merged:hover {
  background-color: #a50e0e !important;
  color: #fff;
}

.icon-merged:hover {
  color: #fff;
}

.overflow-X .colored_table .status {
  min-width: 80px;
  background-color: #fd5454;
  border: 1px solid #fd5454;
  padding: 6px;
  color: #fff;
  border-radius: 50px;
  text-align: center;
}
.overflow-X .colored_table .status.upcoming {
  background-color: #00b69b;
  border: 1px solid #00b69b;
}
.overflow-X .colored_table .status.past {
  background-color: #fcbe2d;
  border: 1px solid #fcbe2d;
}
.section-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.section-heading button {
  background-color: #a50e0e;
  color: #fff;
  border: 1px solid #a50e0e;
  border-radius: 5px;
  padding: 10px;
  min-width: 150px;
}

.submitBtn {
  background-color: #a50e0e;
  color: #fff;
  border: 1px solid #a50e0e;
  border-radius: 5px;
  padding: 10px;
  min-width: 150px;
}

.option-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.option-dropdown i {
  width: 40px;
  height: 40px;
  cursor: pointer;
  line-height: 45px;
  text-align: center;
}

.create-form {
  padding: 20px;
  padding-top: 0;
}

.create-form .group-input {
  padding: 10px;
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.create-form .group-input p {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #7f898b;
}
.create-form .group-input input,
.create-form .group-input select {
  max-width: 360px;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border-radius: 10px;
  background-color: #dae3ea40;
  border: 1px solid #dae3ea40;
  outline: none;
}
.create-form button {
  min-width: 180px;
  max-width: 360px;
  padding: 10px;
  width: auto;
  text-align: center;
  background-color: #a50e0e;
  border: 1px solid #a50e0e;
  border-radius: 4px;
  color: #fff;
  margin: 10px;
  font-size: 14px;
}

.poll-card {
  width: 400px;
  border-radius: 12px;
  border: 1px solid #ffffff;
  box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.08);
}
.poll-card .main-item {
  padding: 20px;
}

.poll-card .main-item .content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
}
.poll-card .main-item .content .item {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.poll-card .main-item .content .item .title {
  font-size: 16px;
  color: #7f898b;
  margin: 0;
  font-weight: 600;
}
.poll-card .main-item .content .item .amt {
  font-size: 14px;
  color: #000000;
  margin: 0;
  font-weight: 600;
}
.poll-card .main-item .content .item button {
  background-color: #a50e0e;
  color: #fff;
  padding: 4px;
  border-radius: 4px;
  width: 50px;
  margin-left: auto;
}
.poll-card .main-item .progress {
  margin-top: 10px;
  --bs-progress-bar-bg: #a50e0e;
}
.poll-card .main-item .colored-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.poll-card .main-item .colored-heading p {
  margin: 0;
  color: #c35053;
  font-weight: 600;
}
.poll-card .last-bg {
  background-color: #dae3ea;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0 10px 10px;
}
.poll-card .last-bg p {
  margin: 0;
  font-size: 14px;
  color: #a50e0e;
  font-weight: 600;
}
.banners {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 1.5rem;
  padding: 20px;
}
.banners img {
  width: 342px;
  height: 150px;
  border-radius: 10px;
}

.view-section {
  padding: 20px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: flex-start;
}
.view-section .Item {
  width: 40%;
  padding: 10px;
}
.view-section .Item .group {
  margin-top: 20px;
}
.view-section .Item .group p {
  margin: 0;
}
.view-section .Item .group p.title {
  color: #7f898b;
  font-size: 20px;
  font-weight: 600;
}
.view-section .Item .group p.value {
  color: #000;
  font-size: 16px;
  margin-top: 5px;
  font-weight: 600;
}
.view-section .Item .group img {
  width: 200px;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
  margin-top: 5px;
}

.view-details {
  padding: 20px;
  padding-top: 0;
}

.view-details p.value {
  margin: 0;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border-radius: 10px;
  font-weight: 600;
  background-color: #dae3ea40;
  border: 1px solid #dae3ea40;
}
.view-details p.label {
  margin: 0;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  padding: 10px;
}

.bannerImg {
  width: 200px;
  height: 100px;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
}

.pagination {
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.pagination button,
.pagination select {
  border: 1px solid #1a9fb2;
  padding: 10px;
}

.prevBtn {
  border: 1px solid #1a9fb2 !important;
  color: #1a9fb2;
  transition: 0.5s;
}
.nextBtn {
  border: 1px solid #1a9fb2 !important;
  color: #1a9fb2;
  transition: 0.5s;
}

.activePage {
  background-color: #1a9fb2;
  border: 1px solid #1a9fb2 !important;
  color: #fff;
}
.pagination select {
  color: #1a9fb2;
  cursor: pointer;
  max-width: 120px;
  outline: none;
  width: 100%;
}
.prevBtn:active,
.prevBtn:focus,
.prevBtn:hover,
.nextBtn:active,
.nextBtn:focus,
.nextBtn:hover {
  background-color: #1a9fb2;
  border: 1px solid #1a9fb2 !important;
  color: #fff;
}

.view-details .overflow-X {
  padding: 0;
}

.filter-btns {
  padding: 20px;
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}
.filter-btns button {
  min-width: 160px;
  max-width: 300px;
  width: auto;
  background-color: #fff;
  color: #a50e0e;
  padding: 10px;
  border: 1px solid #a50e0e;
  border-radius: 5px;
  transition: 0.1s;
}
.filter-btns button:hover,
.filter-btns button.active {
  background-color: #a50e0e;
  color: #fff;
}

.fullscreen-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 1000;
}

.fullscreen-spinner .spinner {
  border: 2px solid #fff;
  border-radius: 50%;
  border-top: 2px solid rgba(0, 0, 0, 0.45);
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.search-container {
  border: 1px solid #ddd;
  padding: 10px 30px;
  display: flex;
  gap: 10px;
  align-items: center;
  border-radius: 5px;
  flex: 1;
}
.search-container input {
  outline: none;
  border: none;
  flex: 1;
}

.search-container-with {
  display: flex;
  gap: 10px;
  padding: 20px;
}
.search-container-with .download-btn {
  background-color: #a50e0e;
  color: #fff;
  border: 1px solid #a50e0e;
  border-radius: 5px;
  padding: 10px;
  min-width: 160px;
  width: auto;
  font-size: 14px;
}

.multiple-images {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
.multiple-images img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: center;  
}
